import { Notification } from 'entities/profile/notifications';
import { StoreSaleType } from 'entities/shop/sale-type';

enum RegistrationStep {
  EMAIL_VERIFICATION = 'EmailVerification',
  PHONE_BIND = 'PhoneBind',
  PHONE_VERIFICATION = 'PhoneVerification',
  COMPLETED = 'Completed',
  EXTERNAL_DATA = 'ExternalDataConfirmation',
  EASY_SIGN_UP = 'EasySignUpConfirmation',
}

type User = {
  accountId: EntityID<number>;
  registrationStepName: RegistrationStep;
  registrationStepRequired: boolean;
  email: string;
  firstName: string;
  lastName: string;
  isReferralProgramAttached: boolean;
  isReferralProgramCompleted: boolean;
  phoneNumber?: string;
  medicalId: string;
  isActionRequired?: boolean;
  referralData?: string;
  hasPassword: boolean;
  dateOfBirth?: DateISOString;
  isDateOfBirthFilledFromDocuments?: boolean;
  isLoyaltyEnabled?: boolean;
  lastSaleType: Exclude<StoreSaleType, undefined> | null;
  lastUsedDeliveryAddress: string | null;
  lastUsedDeliveryLocation: LatLng | null;
  lastUsedDeliveryType: null;
  isGuest: boolean;
  isCaregiver: boolean;
  isCustomerPhoneVerified: boolean;
  notificationSettings?: {
    promotions: Notification[];
    transactions: Notification[];
  };
  name: string;
};

type GetFullNameFallback = string;

const getFullName = (user: User, fallback?: GetFullNameFallback) => {
  const { firstName, lastName } = user;

  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }

  return firstName ?? fallback;
};

export type { User };
export { getFullName, RegistrationStep };
