import { useTimeoutFor } from '@swe/shared/ui-kit/components/action-with-countdown';
import Loader from '@swe/shared/ui-kit/components/loader';
import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';
import { formatDate } from '@swe/shared/utils/date';

import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { considerRequestTimer } from 'common/helpers/consider-request-timer';
import { isOutOfLimits, useCartUtils } from 'common/providers/cart';
import { useGuest } from 'common/providers/guest';
import { useCurrentUser } from 'common/providers/user';
import { useRouterNavigate, useRouterQuery } from 'common/router';
import { Routes } from 'common/router/constants';

import { usePlatformOs } from 'common/use-cases/use-platform-os';
import { UserInfoFormFields } from 'domains/authorization/components/forms/user-info';
import {
  CONFIRM_EMAIL_FORM_NAME,
  CONFIRM_PHONE_FORM_NAME,
} from 'domains/authorization/containers/sign-up/containers/config';
import { useGuestPassPhone } from 'domains/authorization/containers/sign-up/use-cases/use-guest-pass-phone';
import { ResendPhoneVerificationCodeEndpoint } from 'endpoints/authorization/guest/resend-code';
import { GuestSignInData, GuestSignInEndpoint, GuestSignInParams } from 'endpoints/authorization/guest/sign-in';
import { GuestMedicalSignInEndpoint } from 'endpoints/authorization/guest/sign-in-medial';
import { VerifyGuestPhoneEndpoint } from 'endpoints/authorization/guest/verify';
import SignupBackstepEndpoint from 'endpoints/authorization/sign-up/backstep';
import SignupBindPhoneEndpoint from 'endpoints/authorization/sign-up/bind-phone';
import SignupCreateAccountEndpoint from 'endpoints/authorization/sign-up/create-account';
import SignupEasySignUpEndpoint from 'endpoints/authorization/sign-up/easy-sign-up';
import SignupConfirmEasySignUpEndpoint from 'endpoints/authorization/sign-up/ocnfirm-easy-sign-up';
import SignupResendVerifyEmailEndpoint from 'endpoints/authorization/sign-up/resend-email-verification';
import SignupResendVerifyPhoneEndpoint from 'endpoints/authorization/sign-up/resend-phone-verification';
import ConfirmExternalDataEndpoint, {
  Params as ExternalDataParams,
} from 'endpoints/authorization/sign-up/set-birth-date';
import SkipRegistrationStepEndpoint from 'endpoints/authorization/sign-up/skip-registration-step';
import SignupVerifyEmailEndpoint from 'endpoints/authorization/sign-up/verify-email';
import SignupVerifyPhoneEndpoint from 'endpoints/authorization/sign-up/verify-phone';
import ToggleMarketingNotificationEndpoint from 'endpoints/profile/notifications/toggle-marketing-notifications';
import { GetAccountFulfillmentPreferencesEndpoint } from 'endpoints/profile/preferences/get-account-fulfillment';
import { RegistrationStep } from 'entities/authorization/user';
import { showCode } from 'entities/common/show-code';
import { NotificationMethod } from 'entities/profile/notifications';
import { FullShopFulfillmentType, isDelivery, ShopFulfillmentType } from 'entities/shop/info';

const notificationMethods = [NotificationMethod.Sms, NotificationMethod.Push, NotificationMethod.Email];

const useSignUpPrivate = () => {
  const {
    user,
    logout,
    revalidate: revalidateUser,
    isLoaded: isUserLoaded,
    returnURL,
    setReturnURL,
  } = useCurrentUser();
  const isRegistrationStepLoaded = isUserLoaded;

  const query = useRouterQuery();
  const navigate = useRouterNavigate();

  const [isEasySignUp, setIsEasySignUp] = useState(!!query.easySignUpData);
  const isEasySignUpInProgress = useRef(false);

  const [customStep, setCustomStep] = useState<RegistrationStep | undefined>();

  useEffect(() => {
    void (async () => {
      if (isUserLoaded && isEasySignUp && !isEasySignUpInProgress.current) {
        isEasySignUpInProgress.current = true;
        const { easySignUpData, ...restQuery } = query;
        try {
          await SignupEasySignUpEndpoint.request({ data: query.easySignUpData as string });
          await revalidateUser();
          await navigate(Routes.SignUp, { replace: true });
        } catch (e) {
          await navigate({ query: restQuery }, { replace: true });
        } finally {
          setIsEasySignUp(false);
        }
      }
    })();
  }, [isEasySignUp, query, navigate, revalidateUser, isUserLoaded]);

  const [, setConfirmEmailTimeout] = useTimeoutFor(CONFIRM_EMAIL_FORM_NAME);

  const createAccount = useCallback(
    async ({ firstName, lastName, email, password, dateOfBirth, phone, patientId }: UserInfoFormFields) => {
      const res = await SignupCreateAccountEndpoint.request({
        firstName,
        lastName,
        email,
        password,
        dateOfBirth: formatDate(dateOfBirth, 'yyyy-MM-dd'),
        phoneNumber: phone,
        medicalId: patientId,
      });
      setConfirmEmailTimeout(res.nextTryInSeconds);
      showCode(res);
      await revalidateUser();

      return res.nextTryInSeconds;
    },
    [revalidateUser, setConfirmEmailTimeout],
  );

  const confirmEasySignUp = useCallback(
    async ({ firstName, lastName, email, password, dateOfBirth, phone, patientId }: UserInfoFormFields) => {
      await SignupConfirmEasySignUpEndpoint.request({
        firstName,
        lastName,
        email,
        password,
        dateOfBirth: formatDate(dateOfBirth, 'yyyy-MM-dd'),
        phoneNumber: phone,
        medicalId: patientId,
      });
      await revalidateUser();
    },
    [revalidateUser],
  );

  const [, setConfirmPhoneTimeout] = useTimeoutFor(CONFIRM_PHONE_FORM_NAME);

  const bindPhone = useCallback(
    async (phone: string) => {
      const res = await SignupBindPhoneEndpoint.request({ phoneNumber: phone });
      setConfirmPhoneTimeout(res.nextTryInSeconds);
      showCode(res);
      await revalidateUser();
    },
    [revalidateUser, setConfirmPhoneTimeout],
  );

  const verifyEmail = useCallback(
    async (verificationCode: string) => {
      try {
        await SignupVerifyEmailEndpoint.request({ code: verificationCode });
        await revalidateUser();
      } catch (e) {
        console.error(e);
      }
    },
    [revalidateUser],
  );

  const changePhone = useCallback(async () => {
    try {
      await SignupBackstepEndpoint.request(undefined);
      await revalidateUser();
    } catch (e) {
      console.error(e);
    }
  }, [revalidateUser]);

  const skipStep = useCallback(async () => {
    try {
      await SkipRegistrationStepEndpoint.request(undefined);
      await revalidateUser();
    } catch (e) {
      console.error(e);
    }
  }, [revalidateUser]);

  const changeEmail = useCallback(logout, [logout]);

  const resendEmailVerificationCode = useCallback(async () => {
    return considerRequestTimer(SignupResendVerifyEmailEndpoint.request, { params: undefined });
  }, []);

  const getNotificationSettings = useCallback(
    (enabled: boolean) => notificationMethods.map((method) => ({ notificationMethod: method, enabled })),
    [],
  );

  const setNotificationSettings = useCallback(
    async (enabled: boolean) => {
      const settings = getNotificationSettings(enabled);
      return Promise.all(settings.map((setting) => ToggleMarketingNotificationEndpoint.request(setting)));
    },
    [getNotificationSettings],
  );

  const confirmExternalData = useCallback(
    async (values: ExternalDataParams) => {
      await ConfirmExternalDataEndpoint.request(values);
      await revalidateUser();
    },
    [revalidateUser],
  );

  type GuestLoginHandler = (r: Routes | null) => Promise<void>;

  const onSuccessGuestLogin = useRef<GuestLoginHandler>();

  const setSuccessGuestLoginHandler = useCallback((fn: GuestLoginHandler) => {
    onSuccessGuestLogin.current = fn;
  }, []);

  const guestData = useRef<GuestSignInData>();

  const { mutate: cartUpdate, setSkipNextCartUpdate } = useCartUtils();

  const { platformOs } = usePlatformOs();
  const { isGuest, setIsGuestUser } = useGuest();
  const { data } = GetAccountFulfillmentPreferencesEndpoint.useRequest();

  const orderType = useMemo(
    () => (data && isDelivery(data.fulfillmentType) ? ShopFulfillmentType.Delivery : ShopFulfillmentType.Pickup),
    [data],
  );

  const { setPhone } = useGuestPassPhone();

  const continueAsGuest = useCallback(
    async ({
      firstName,
      lastName,
      email,
      dateOfBirth,
      phone,
      optIn,
      isMedical = false,
    }: Omit<GuestSignInParams, 'dateOfBirth' | 'platformOs'> & { dateOfBirth: Date | string; isMedical?: boolean }) => {
      const params = {
        firstName,
        lastName,
        email,
        ...(dateOfBirth ? { dateOfBirth: formatDate(dateOfBirth, 'yyyy-MM-dd') } : {}),
        ...(phone ? { phone } : {}),
        ...(optIn ? { optIn: true } : {}),
        platformOs,
      };
      const method = isMedical ? GuestMedicalSignInEndpoint : GuestSignInEndpoint;
      const res = await method.request({
        ...params,
        fulfillmentType:
          orderType === ShopFulfillmentType.Delivery
            ? FullShopFulfillmentType.Delivery
            : FullShopFulfillmentType.InStorePickup,
      });
      if (res) {
        setPhone(phone);
        showCode(res);
        guestData.current = res;
        setConfirmPhoneTimeout(res.nextTryIn);
        setCustomStep(RegistrationStep.PHONE_VERIFICATION);
      }
      setReturnURL(null);
      setSkipNextCartUpdate(true);

      setIsGuestUser(undefined);

      await revalidateUser();
      const cart = await cartUpdate();

      if (returnURL === Routes.Cart && !isOutOfLimits(cart?.order?.limits || [])) {
        setReturnURL(Routes.Checkout);
      } else {
        setReturnURL(returnURL);
      }

      return res?.nextTryIn;
    },
    [
      cartUpdate,
      orderType,
      platformOs,
      returnURL,
      revalidateUser,
      setConfirmPhoneTimeout,
      setIsGuestUser,
      setPhone,
      setReturnURL,
      setSkipNextCartUpdate,
    ],
  );

  const verifyPhone = useCallback(
    async (verificationCode: string) => {
      if (isGuest) {
        if (!guestData.current?.id) {
          return;
        }

        await VerifyGuestPhoneEndpoint.request({
          number: verificationCode,
          id: guestData.current?.id,
        });
        setCustomStep(undefined);
      } else {
        await SignupVerifyPhoneEndpoint.request({ code: verificationCode });
        await revalidateUser();
      }
    },
    [revalidateUser, isGuest],
  );

  const resendPhoneVerificationCode = useCallback(async () => {
    if (isGuest) {
      if (!guestData.current?.id) {
        return 0;
      }
      return considerRequestTimer(ResendPhoneVerificationCodeEndpoint.request, {
        params: {
          confirmationId: guestData.current?.id,
        },
      });
    }
    return considerRequestTimer(SignupResendVerifyPhoneEndpoint.request, { params: undefined });
  }, [isGuest]);

  return {
    isLoaded: isRegistrationStepLoaded,
    user,
    step: user && {
      name: customStep ?? user.registrationStepName,
      required: user.registrationStepRequired,
    },
    skipStep,
    createAccount,
    bindPhone,
    verifyPhone,
    verifyEmail,
    changePhone,
    changeEmail,
    resendEmailVerificationCode,
    resendPhoneVerificationCode,
    setNotificationSettings,
    revalidateCurrentStep: revalidateUser,
    confirmExternalData,
    confirmEasySignUp,
    isEasySignUp,
    returnURL,
    continueAsGuest,
    setSuccessGuestLoginHandler,
  };
};

const signUpContext = createContext<ReturnType<typeof useSignUpPrivate>>(null!);
const SignUpContextProvider = signUpContext.Provider;
const useSignUp = () => useContext(signUpContext);

type SignUpProviderProps = ComponentHasChildren;
const SignUpProvider = ({ children }: SignUpProviderProps) => {
  const value = useSignUpPrivate();
  return (
    <SignUpContextProvider value={value}>{value.isEasySignUp ? <Loader centered /> : children}</SignUpContextProvider>
  );
};

export { useSignUp, SignUpProvider };
